<template>
    <Menu actual="/material-didactico" class="mt-6" />

	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
			<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
			<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
				<div>
					<h2 class="text-base text-morado font-semibold tracking-wide uppercase">9 años</h2>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-verde sm:text-4xl">Una nueva vida</h3>
				</div>
			</div>
			<div class="mt-6 lg:grid lg:grid-cols-2 lg:gap-8">
				<div class="relative lg:row-start-1 lg:col-start-2">
					<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
					</svg>
					<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
						<figure>
							<div>
								<img class="rounded-lg shadow-lg max-h-96 md:max-h-screen mx-auto" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="Una nueva vida" />
							</div>
						</figure>
					</div>

					<div class="mt-12 hidden md:flex justify-around">
						<router-link to="/material-didactico/yo-me-quiero-yo-me-cuido">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero yo me cuido" />
						</router-link>
						<router-link to="/material-didactico/como-soy">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
						</router-link>
					</div>
				</div>
				<div class="mt-8 lg:mt-0">
					<div class="flex space-x-2 items-center">
						<a href="https://apps.apple.com/us/app/una-nueva-vida/id1608935462" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
						<a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.unanuevavida" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
					</div>
					<div class="mt-4 text-base max-w-prose mx-auto">
						<p class="text-xl font-light text-gray-500">App con 23 juegos interactivos y un libro descargable en PDF.</p>
					</div>

					<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
						<div class="text-azul-light font-bold text-xl mt-12">¿Qué aprenden?</div>
						
						<p>Las preguntas e inquietudes de niñas y niños  van de acuerdo a  su capacidad pensante.</p>

						<p>Alrededor de los 7 años u 8  años, se genera un progreso ,  el pensamiento,  empieza a ser lógico y se inicia la reflexión, lo que los lleva a analizar la causa y efecto de las cosas,  por lo que  es normal que la mayoría  se pregunte por la labor del padre en la concepción.</p>

						<p>Es común que a estas edades  se pregunten  ¿de dónde vienen, o cómo llegaron al mundo?</p>

						<p>Sabemos que  esta es la pregunta a la que temen muchos adultos por lo que con el material <span class="font-semibold">Una nueva vida</span> te ayudamos a atender su educación sexual con oportunidad y no correr el  riesgo de que reciban información de otras fuentes.</p>

						<p>El material <span class="font-semibold">Una nueva vida</span> te ayudará a  resolver estas inquietudes de forma positiva y natural.</p>

						<p>Nuestra labor es facilitarte la forma de abordar el tema para que:</p>
						<ul>
							<li>Aprendan  de forma interesante y divertida información básica sobre el proceso de reproducción y embarazo.</li>
							<li>Entiendan  el para qué de la maduración de los aparatos reproductores y sus nuevas funciones.</li>
							<li>Comprendan la importancia de la reproducción y su significado en la vida de las personas, cuestión que es imprescindible  en su formación. Entre más informados este un niño(a) acerca de este tema, menor el riesgo de obtener información equivocada.</li>
							<li>Te ayudamos a atender su educación sexual  con oportunidad y no correr el riesgo de que reciban información de otras fuentes, y ocupen tu lugar</li>
						</ul>

						<p>Debemos considerar que durante estos primeros años de primaria el niño debe recibir la información básica sobre la reproducción, pues en muchos casos alrededor de los 9 o 10 años las niñas inician el proceso de la pubertad, o en ocasiones ya menstrúan. Por lo que resulta vital el poder entender a que se deben los cambios físicos y funcionales.</p>
					</div>
				</div>
			</div>

			<div class="mt-12 md:hidden flex justify-around">
				<router-link to="/material-didactico/yo-me-quiero-yo-me-cuido">
					<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero yo me cuido" />
				</router-link>
				<router-link to="/material-didactico/como-soy">
					<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
// import { AcademicCapIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	}
}
</script>